<template>
  <div>
    <div class="logo text-primary">Ramid</div>
    <div class="logo__subline">Stolárstvo Miloš Kaščák, <span class="small" v-html="slogan"></span></div>
  </div>
</template>

<script>

    export default {
        name: 'Index',
        props: ['slogan'],
        data: function() {
            return {
            };
        },
    };
</script>
